<template>
    <div class="stats-table-chart">
        <div class="stats-values score-value" :class="getStatusClass(score)">
            <div class="stats-values-label"></div>
            <div class="stats-values__value">{{ numberDecimalsFormat(score) }}</div>
        </div>
        <div class="stats-values">
            <div class="stats-values-label"></div>
            <div class="stats-values__value">{{ numberDecimalsFormat(importance) }}</div>
        </div>
        <div class="stats-values gap-value" :class="getGapStatusClass(gap)">
            <div class="stats-values-label"></div>
            <div class="stats-values__value">{{ numberDecimalsFormat(gap) }}</div>
        </div>
    </div>

</template>

<script>
import { getStatusClass, getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
    name: "StatsTableChart",
    props: {
        score: Number,
        importance: Number,
        gap: Number,
    },
    data() {
        return {
            getStatusClass,
            getGapStatusClass,
            numberDecimalsFormat
        }
    }
}
</script>

<style scoped lang="scss">
.stats-table-chart {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;

    .stats-values {
        position: relative;
        display: flex;
        align-items: center;
        .stats-values-label {
            position: relative;
            background-color: #CED4F8;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            margin-right: 4px;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 6px;
            }
        }
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            opacity: 0.5;
            margin-right: 4px;
        }
        &__value {
            font-weight: 700;
            font-size: 16px;
            line-height: 13px;
            color: #000000;
            opacity: 0.87;
        }

        &.score-value {
            &.status-success {
                &:before {
                    background: #2BC490;
                }
                .stats-values-label {
                    background-color: #2BC490;
                }
            }
            &.status-danger {
                &:before {
                    background: #E22D21;
                }
                .stats-values-label {
                    background-color: #E22D21;
                }
            }
            &.status-warning {
                &:before {
                    background: #FAD03B;
                }
                .stats-values-label {
                    background-color: #FAD03B;
                }
            }
        }
        &.gap-value {
            &.status-success .stats-values-label {
                background-color: #DBF7ED;
                &:after {
                    background-image: url("/media/buying/icons/gap-value-icon.svg");
                    filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
                }
            }
            &.status-danger .stats-values-label {
                background-color: #FEF4F4;
                &:after {
                    background-image: url("/media/buying/icons/gap-value-icon.svg");
                    filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
                }
            }
            &.status-warning .stats-values-label {
                background-color: #fff7dc;
                &:after {
                    background-image: url("/media/buying/icons/gap-value-icon.svg");
                    filter: invert(79%) sepia(100%) saturate(853%) hue-rotate(325deg) brightness(102%) contrast(96%);
                }
            }
        }
    }
}
</style>
