<template>
    <div
        class="compare-banks__filter"
        :class="{
            'is-empty': compareBanksPageData.isColumnEmpty(index)
        }"
    >
        <div class="d-flex align-items-center justify-content-between">
            <div class="compare-banks__filter--label">
                {{ index === 0 ? $t('Benchmark') : $t('Bank(s)') }}
            </div>
            <div
                v-if="index !==0 && !compareBanksPageData.filterSection[index].isEmpty()"
                class="compare-banks__filter--action"
                @click="clearFilter(index)"
            >
                {{ $t('Clear') }}
            </div>
        </div>
        <SelectBoxWithModal
            v-model="compareBanksPageData.filterSection[index].bank_ids"
            multiple
            :all-selected-text="$t('All Bank(s)')"
            :placeholder="$t('Select Bank')"
            :options="businessBanks"
            :is-disabled="compareBanksPageData.isContainerLoading"
            :custom-selection-text="$t('Banks Selected (COUNT)', {count: compareBanksPageData.filterSection[index].bank_ids.length})"
            @onTouch="handleFilterTouch"
        />
    </div>
    <div
        class="compare-banks__filter--bottom"
        :class="{
            'is-empty': compareBanksPageData.isColumnEmpty(index)
        }"
    >
        <div class="filter-countries-select">
            <div class="filter-countries-select__label">
                <div class="d-flex align-items-center">
                    <inline-svg src="/media/buying/icons/map-black-icon.svg" width="14px" height="14px"></inline-svg>
                    <span class="ms-1">{{ $t('Countries') }}</span>
                </div>
                <div class="d-flex align-items-center">
                    <strong class="me-4">{{ compareBanksPageData.filterSection[index].countries.length }}</strong>
                    <inline-svg src="/media/buying/icons/edit_black.svg" width="16px" height="16px"></inline-svg>
                </div>
            </div>
            <SelectBoxWithModal
                v-model="compareBanksPageData.filterSection[index].countries"
                :options="businessCountries"
                :is-disabled="compareBanksPageData.isContainerLoading"
                self-value
                multiple
                @onTouch="handleFilterTouch"
            />
        </div>
        <div
            v-if="!compareBanksPageData.isColumnEmpty(index)"
            class="table-headings"
        >
            <div class="table-headings__item">{{ $t('Score') }}</div>
            <div class="table-headings__item">{{ $t('Importance') }}</div>
            <div class="table-headings__item">{{ $t('Gap') }}</div>
        </div>
    </div>
</template>

<script>
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";

export default {
    name: "ColumnFilter",
    components: {
        SelectBoxWithModal
    },
    props: {
        compareBanksPageData: Object,
        businessCountries: Array,
        businessBanks: Array,
        dataForWatch: Object,
        index: Number,
        isLoading: Boolean,
    },
    emits: ['onFilterChange', 'onFilterClear'],
    methods: {
        clearFilter(index) {
            this.$emit('onFilterClear', index);
        },
        handleFilterTouch() {
            if (this.dataForWatch.isValid()) {
                this.$emit('onFilterChange', {
                    index: this.index
                })
            }
        }
    }
};
</script>
