<template>
    <div
        v-if="compareBanksPageData"
        class="compare-banks"
    >
        <div class="compare-banks__top">
            <div class="w-40 compare-banks__top--item">
                <div class="compare-banks__filter">
                    <div class="compare-banks__filter--label">
                        {{ $t('Product Area') }}
                    </div>
                    <SelectBoxWithModal
                        v-model="globalFilter.product_area"
                        self-value
                        show-badge
                        :options="businessProductAreas"
                        :is-disabled="compareBanksPageData.isContainerLoading"
                    />
                </div>
                <div class="compare-banks__filter--bottom">
                    <div class="filter-date-range-picker">
                        <div class="compare-banks__filter--label">
                            {{ $t('Time Period') }}
                        </div>
                        <DateRangePicker
                            v-model="globalFilter.time_period"
                            :is-disabled="compareBanksPageData.isContainerLoading"
                        />
                    </div>
                </div>
            </div>
            <div
                v-for="i of compareBanksPageData.maxColumns"
                class="w-20 compare-banks__top--item"
                ref="originContent"
            >
                <ColumnFilter
                    :compareBanksPageData="compareBanksPageData"
                    :businessCountries="businessCountries"
                    :businessBanks="businessBanks"
                    :isLoading="compareBanksPageData.isColumnLoading(i-1)"
                    :index="i-1"
                    :dataForWatch="compareBanksPageData.filterSection[i-1]"
                    @onFilterChange="handleColumnFilterChange"
                    @onFilterClear="handleColumnFilterClear"
                />
                <div
                    v-if="compareBanksPageData.isColumnEmpty(i-1)"
                    class="compare-banks__empty-container"
                    :style="{
                        width: `${emptyColumnWidth}px`,
                        height: `${emptyColumnHeight}px`
                    }"
                >
                    <EmptyColumn
                        :isLoading="compareBanksPageData.isColumnLoading(i-1)"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="compareBanksPageData.phaseSection"
            ref="totalScoreContainer"
            class="compare-banks__total-score"
        >
            <div
                    v-for="(phaseItem, phaseIndex) in compareBanksPageData.phaseSection"
                    :key="phaseIndex"
                    class="compare-banks__total-score--item"
                    :class="phaseIndex%2 === 0 ? 'even' : 'odd'"
            >
                <div
                    class="w-40"
                    :class="{
                        'phase-item': !phaseItem.isTotal
                    }"
                >
                    <div class="cb-table-item d-flex align-items-center ps-5">
                        <span class="label-16">{{ phaseItem.name }}</span>
                    </div>
                </div>

                <div
                    v-for="(statsItem, phaseStatsIndex) in phaseItem.rowStats"
                    :key="phaseStatsIndex"
                    class="w-20"
                >
                    <template v-if="statsItem">
                        <div class="cb-table-item">
                            <StatsTableChart
                                v-if="!compareBanksPageData.isColumnLoading(phaseStatsIndex)"
                                :score="statsItem.score"
                                :importance="statsItem.importance"
                                :gap="statsItem.gap"
                            />
                            <CustomLoader v-else />
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div
            v-if="compareBanksPageData.questionSection"
            ref="questionHeaderContainer"
            class="compare-banks__question-header"
        >
            <div class="d-flex align-items-center w-40">
                <div class="compare-banks__question-header--title">
                    {{ $t('Questions') }}
                </div>
                <div class="compare-banks__question-header--count">
                    {{ compareBanksPageData.questionSection.length }}
                </div>
            </div>
            <div
                v-for="i in compareBanksPageData.maxColumns"
                :key="i"
                class="w-20"
            >
                <div
                    v-if="!compareBanksPageData.isColumnEmpty(i-1)"
                    class="d-flex align-items-center"
                >
                    <div class="compare-banks__question-header--sort-label">
                        {{ $t('Sort Column') }}
                    </div>
                    <DashboardSortSelect
                        class="compare-banks__question-header--sort"
                        :show-badge="compareBanksPageData.lastSortedIndex === i-1"
                        :disabled="compareBanksPageData.isContainerLoading"
                        @onSortingChange="sortSection(i-1, $event)"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="compareBanksPageData.questionSection"
            ref="questionScoreContainer"
            class="compare-banks__question-score"
        >
            <transition-group tag="div" class="list-section" name="group-transition">
                <div
                    v-for="(questionItem, questionIndex) in compareBanksPageData.questionSection"
                    :key="questionItem.question.id"
                    class="compare-banks__question-score--item"
                    :class="questionIndex%2 === 0 ? 'even' : 'odd'"
                >
                    <div
                        @click="handleClickOnQuestion(questionItem.question.id)"
                        class="w-40 pointer">
                        <div class="cb-table-item">
                            <div class="compare-banks__question-score--item_label">
                                <div class="compare-banks__question-score--item_label_phase">
                                    {{ questionItem.question.phase }}
                                </div>
                                <div class="compare-banks__question-score--item_label_id">
                                    {{ $t('ID') }} <span>{{ questionItem.question.id }}</span>
                                </div>
                            </div>
                            <div class="compare-banks__question-score--item_content">
                                {{ questionItem.question.text }}
                            </div>
                        </div>
                    </div>

                    <div
                        v-for="(statsItem, rowStatsIndex) in questionItem.rowStats"
                        :key="rowStatsIndex"
                        class="w-20"
                    >
                        <template v-if="statsItem">
                            <div class="cb-table-item">
                                <StatsTableChart
                                    v-if="!compareBanksPageData.isColumnLoading(rowStatsIndex)"
                                    class="pointer"
                                    :score="statsItem.score"
                                    :importance="statsItem.importance"
                                    :gap="statsItem.gap"
                                    @click="handleClickOnQuestionStats(questionItem.question.id, rowStatsIndex)"
                                />
                                <CustomLoader v-else />
                            </div>
                        </template>
                    </div>
                </div>
            </transition-group>
        </div>

        <CustomLoader
            v-if="compareBanksPageData && compareBanksPageData.isContainerLoading"
            full-screen
        />
    </div>
</template>

<script>
import { diagLog } from "@/core/helpers/GlobalHelper";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { CompareBankContainer } from "@/store/models/business/compare/CompareBankContainer";
import store from "@/store";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";
import DateRangePicker from "@/buying-teams/shared-components/date-time/DateRangePicker";
import StatsTableChart from "@/buying-teams/pages/business/dashboard/components/compare/StatsTableChart";
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect";
import EmptyColumn from "@/buying-teams/pages/business/dashboard/components/compare/EmptyColumn";
import ColumnFilter from "@/buying-teams/pages/business/dashboard/components/compare/ColumnFilter";

export default {
    name: "BusinessCompareBanks",
    components: {
        ColumnFilter,
        EmptyColumn,
        DashboardSortSelect,
        StatsTableChart,
        DateRangePicker,
        SelectBoxWithModal,
        CustomLoader
    },
    watch: {
        'globalFilter.product_area': {
            async handler(n, o) {
                let needQuestionUpdate = false;

                if (o === '') {
                    return;
                } else if (n !== o) {
                    needQuestionUpdate = true;
                }

                await this.applyGlobalFilters(needQuestionUpdate);
            },
        },
        'globalFilter.time_period': {
            async handler(n, o) {
                await this.applyGlobalFilters();
            },
        }
    },
    data() {
        return {
            compareBanksPageData: null,
            globalFilter: {
                product_area: '',
                time_period: {
                    start_date: "",
                    end_date: ""
                }
            },
            emptyColumnWidth: 0,
            emptyColumnHeight: 0
        }
    },
    computed: {
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        businessCountries() {
            return store.getters.businessCountries;
        },
        businessBanks() {
            return store.getters.businessBanks;
        },
        businessBaseFilterDefaultState() {
            return store.getters.businessBaseFilterDefaultState;
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.$t('Compare your banks'),
        });
    },
    async mounted() {
        this.globalFilter.product_area = this.businessProductAreas[0];

        this.detectEmptyColumnSizes();
        window.addEventListener('resize', this.detectEmptyColumnSizes);
        try {
            this.compareBanksPageData = new CompareBankContainer();
            this.compareBanksPageData.setFilter(0, {
                bank_ids: this.businessBanks.map(bk => bk.id),
                countries: this.businessCountries
            });
            await this.applyGlobalFilters(true);
        } catch (e) {
            console.error(e);
        }
        diagLog( 'COMPARE PAGE DATA => ', this.compareBanksPageData);
    },
    unmounted() {
        window.removeEventListener('resize', this.detectEmptyColumnSizes);
    },
    methods: {
        async applyGlobalFilters(needQuestionUpdate = false) {
            diagLog('APPLYING GLOBAL FILTERS');
            this.compareBanksPageData.setContainerLoadingState(true);

            if (needQuestionUpdate) {
                let questions = await store.dispatch('fetchProductAreaQuestions', this.globalFilter.product_area);
                this.compareBanksPageData.setQuestions(questions);
            }

            let promises = [];
            for (let i = 0; i < this.compareBanksPageData.filterSection.length; i++) {
                let filter = this.compareBanksPageData.filterSection[i];
                this.removeSection(filter.index);
                if (filter.isValid()) {
                    // await this.filterSection(filter.index);
                    promises.push(this.filterSection(filter.index));
                }

            }
            await Promise.allSettled(promises);
            this.compareBanksPageData.setContainerLoadingState(false);
        },
        async handleColumnFilterChange({ index }) {
            await this.filterSection(index);
        },
        async filterSection(index) {
            this.detectEmptyColumnSizes();
            this.compareBanksPageData.setColumnLoadingState(index, true);
            this.compareBanksPageData.unsetSortIndex(index);

            let statsData = await store.dispatch('fetchQuestionStats', {
                ...this.compareBanksPageData.filterSection[index],
                product_areas: [this.globalFilter.product_area],
                time_period: [this.globalFilter.time_period],
            })

            this.compareBanksPageData.applyFilter(index, statsData);
            this.compareBanksPageData.setColumnLoadingState(index, false);
        },
        removeSection(index) {
            this.compareBanksPageData.removeStats(index);
        },
        sortSection(index, what) {
            this.compareBanksPageData.sortBy(index, what);
        },
        detectEmptyColumnSizes() {
            setTimeout(_ => {
                this.emptyColumnWidth = this.$refs.originContent ? this.$refs.originContent[0].offsetWidth + 1 : 100;
                let totalScoreContainer = this.$refs.totalScoreContainer ? this.$refs.totalScoreContainer.offsetHeight : 0;
                let questionHeaderContainer = this.$refs.questionHeaderContainer ? this.$refs.questionHeaderContainer.offsetHeight : 0;
                let questionScoreContainer = this.$refs.questionScoreContainer ? this.$refs.questionScoreContainer.offsetHeight : 0;
                this.emptyColumnHeight = totalScoreContainer + questionHeaderContainer + questionScoreContainer;
            })
        },
        handleColumnFilterClear(index) {
            this.compareBanksPageData.filterSection[index].clear();
            this.compareBanksPageData.removeStats(index);
        },
        handleClickOnQuestion(questionId) {
            let filter = {
                bank_ids: this.businessBaseFilterDefaultState.bank_ids,
                countries: this.businessBaseFilterDefaultState.countries,
                survey_ids: [],
                time_period: {
                    start_date: this.globalFilter.time_period.start_date || '',
                    end_date: this.globalFilter.time_period.end_date || '',
                },
            };
            store.commit("SAVE_BUSINESS_SINGLE_QUESTION_FILTER", filter);
            this.$router.push(`/business/questions/${questionId}`);
        },
        handleClickOnQuestionStats(questionId, statsIndex) {
            let filter = this.compareBanksPageData.filterSection[statsIndex];
            let questionFilter = {
                bank_ids: filter.bank_ids.length ? filter.bank_ids : this.businessBaseFilterDefaultState.bank_ids,
                countries: filter.countries.length ? filter.countries : this.businessBaseFilterDefaultState.countries,
                survey_ids: [],
                time_period: {
                    start_date: this.globalFilter.time_period.start_date || '',
                    end_date: this.globalFilter.time_period.end_date || '',
                },
            };
            store.commit("SAVE_BUSINESS_SINGLE_QUESTION_FILTER", questionFilter);
            this.$router.push(`/business/questions/${questionId}`);
        }
    }
};
</script>

<style lang="scss">
$mainBorder: 1px solid rgba(0, 0, 0, 0.1);
.compare-banks {
    width: 100%;
    .cb-table-item {
        background: #FDF7F1;
        height: 65px;
        width: 100%;
        border: 1px solid #E5E5E5;
    }
    .label-16 {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.1px;
    }
    &__top {
        display: flex;
        position: sticky;
        z-index: 1;
        top: 121px;
        background: #F6F6F6;
        transition: .3s;

        &:after, &:before {
            content: "";
            position: absolute;
            background: #F6F6F6;
            width: 50px;
            height: 100%;
        }

        &:after {
            left: -50px;
        }

        &:before {
            width: 30px;
            right: -30px;
        }

        &--item {
            box-sizing: border-box;
            &:first-child {
                .compare-banks__filter, .compare-banks__filter--bottom {
                    padding-left: 0;
                }
            }
        }
    }
    &__total-score {
        &--item {
            display: flex;
            > div:not(:first-child) {
                .cb-table-item {
                    border-left: 0;
                }
            }
            .phase-item {
                margin-left: 44px;
                position: relative;
                &:before {
                    content: '';
                    height: 65px;
                    width: 27px;
                    position: absolute;
                    left: -27px;
                    top: 0;
                    background-image: url('/media/buying/list-lines-item.svg');
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
            }

            &.odd {
                div {
                    background: #FEFBF8;
                }
            }

            &:last-child {
                .phase-item {
                    margin-left: 44px;
                    position: relative;
                    &:before {
                        background-image: url('/media/buying/list-lines-item-last.svg');
                    }
                }
            }
        }
    }
    &__question-header {
        padding: 24px 0 20px 0;
        display: flex;

        &--title {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #434343;
            opacity: 0.6;
        }
        &--count {
            background: #ECECEC;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
        }

        &--sort-label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #434343;
            opacity: 0.6;
            margin-left: 17px;
        }

        &--sort {
            margin-left: auto;
            margin-right: 47px;
        }

        .el-input__inner {
            display: none;
        }
    }
    &__question-score {
        &--item {
            display: flex;

            .cb-table-item {
                height: 125px;
                padding: 22px 16px;
            }

            > div:not(:first-child) {
                .cb-table-item {
                    border-left: 0;
                }
            }

            &.odd {
                div {
                    background: #FEFBF8;
                }
            }

            &_label {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &_phase {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    color: #030303;
                }

                &_id {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #969696;

                    span {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 158.02%;
                        color: #0A0A0A;
                    }
                }
            }

            &_content {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 0.1px;
                color: #2A2A2A;
                margin-top: 16px;
            }
        }
    }
    &__filter {
        padding: 17px 12px 0 12px;

        &--label {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #434343;
            opacity: 0.6;
            margin-bottom: 8px;
        }

        &--action {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #E22D21;
            margin-bottom: 8px;
            cursor: pointer;
        }
        .select-box-with-badge__input {
            max-width: 360px;
            .el-select .el-input .el-input__suffix {
                right: 0;
                transform: rotate(-90deg);
            }
            .el-input__inner::placeholder,
            .el-input__inner {
                height: 37px;
                padding-left: 8px !important;
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: rgba(0, 0, 0, 0.87);
            }
            .el-input__inner {
                border-bottom: 2px solid #363636;
                border-radius: 0;
                &::placeholder {
                    font-weight: 700 !important;
                    font-size: 16px !important;
                }
            }
        }
        &--bottom {
            padding: 0 12px;
            min-height: 105px;
            border-right: $mainBorder;
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-date-range-picker {
                display: flex;
                align-items: center;
                .compare-banks__filter--label {
                    margin-bottom: 0;
                    margin-right: 16px;
                }
                .date-range-picker {
                    min-width: 188px;
                    height: 33px;
                    &__input {
                        padding: 8px;
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        &--value {
                            span, strong {
                                font-size: 12px;
                                line-height: 15px;
                            }
                        }
                    }
                }
            }
            .filter-countries-select {
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                height: 33px;
                position: relative;

                .select-box__badge {
                    display: none;
                }
                .select-box-with-badge {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    &__input {
                        .is-all-selected__text,
                        .is-add-more__text {
                            display: none;
                        }
                        .el-input__inner, .el-input__inner::placeholder {
                            color: transparent !important;
                        }
                        .el-input__inner {
                            height: 33px;
                            background: transparent;
                        }
                        .el-input__suffix {
                            display: none;
                        }
                    }
                }
                &__label {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 33px;
                    padding: 8px;
                    svg path {
                        fill: #435BF4;
                    }
                    span {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        color: #313131;
                    }
                    strong {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17px;
                        color: #363636;
                    }
                }
            }
            .table-headings {
                display: flex;
                justify-content: space-around;
                &__item {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    opacity: 0.5;
                    padding-bottom: 9px;
                }
            }
            &.is-empty {
                background: #F0F6FA;
                border: 1px solid #76C7F0;
                border-bottom: unset;
                border-top: unset;
            }
        }
        .custom-selection-label__text, .is-all-selected__text {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 13px;
            color: #363636;
        }

        &.is-empty {
            background: #F0F6FA;
            border: 1px solid #76C7F0;
            border-bottom: unset;

            .custom-selection-label__text {
                font-size: 18px;
                color: #435BF4;
            }
            .el-input--suffix {
                input {
                    background: #F0F6FA !important;
                    border-bottom: 2px solid #435BF4 !important;
                }
                .el-input__suffix-inner {
                    .el-input__icon {
                        svg {
                            path {
                                fill: #435BF4;
                            }
                        }
                    }
                }
            }
        }
    }
    &__empty-container {
        height: 100vh;
        position: absolute;
        z-index: 99;
        background: #F0F6FA;
        border: 1px solid #76C7F0;
        border-top: unset;
    }
}

.w-40 {
    &.phase-item {
        width: calc(40% - 44px);
    }
}

.cb-table-item {
    transition: .1s ease-in-out;
    &:hover {
        background: #F6F9FF !important;
        box-shadow: 0px 9px 27px rgba(0, 0, 0, 0.06);
        scale: 1.01;
        .compare-banks__question-score--item_label,
        .compare-banks__question-score--item_label_phase,
        .compare-banks__question-score--item_label_id,
        .compare-banks__question-score--item_content,
        .stats-table-chart{
            background: #F6F9FF;
        }
    }
}
</style>
