<template>
    <div v-if="!isLoading" class="compare-banks-column-empty">
        <img src="/media/buying/compare-bank-empty-column.png" alt="">
        <div class="compare-banks-column-empty_context">
            <div class="compare-banks-column-empty_context__title">
                {{ $t('Select Bank') }}
            </div>
            <div class="compare-banks-column-empty_context__subtitle">
                {{ $t('Select the bank you want to compare') }}
            </div>
        </div>
    </div>
    <div v-else>
        <CustomLoader />
    </div>
</template>

<script>
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
export default {
    name: "EmptyColumn",
    components: {
        CustomLoader
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.compare-banks-column-empty {
    margin-top: 47px;
    text-align: center;

    img {
        width: 100%;
        padding: 0 25px;
    }
    &_context {
        font-style: normal;
        margin-top: 16px;

        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #8DB4CD;
        }
        &__subtitle {
            padding: 0 20px;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            color: #8DB4CD;
        }
    }
}
</style>
