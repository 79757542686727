import store from "@/store";
import { translateGlobal } from "@/core/helpers/GlobalHelper";

export class CompareBankContainer {
    filterSection = [] as CompareFilter[];
    phaseSection = [] as PhaseStatsRow[];
    questionSection = [] as QuestionSectionRow[];
    maxColumns = 3;
    phases = store.getters.phases;
    totalScoreSectionItems = [translateGlobal('Total')] as string[];
    phaseSectionRowMapping = {} as {[key: string]: PhaseStatsRow};
    questionSectionRowMapping = {} as {[key: number]: QuestionSectionRow};
    lastSortedIndex = null as null | number;
    loadingColumns = {};
    isContainerLoading = false;

    constructor(obj: any) {
        for (let i = 0; i < this.maxColumns; i++) {
            this.filterSection.push(new CompareFilter(i));
        }
        if (this.phases) {
            this.totalScoreSectionItems = this.totalScoreSectionItems.concat(this.phases);
        }
        this.setQuestions(obj && obj.questions ? obj.questions : []);
        this.setPhases();
    }

    setPhases(): void {
        this.phaseSection = [];
        this.phaseSectionRowMapping = {} as {[key: string]: PhaseStatsRow};

        for (let i = 0; i < this.totalScoreSectionItems.length; i ++) {
            let phs = new PhaseStatsRow(this.totalScoreSectionItems[i])
            this.phaseSectionRowMapping[phs.name] = phs;
            this.phaseSection.push(phs);
        }
    }

    setQuestions(questions): void {
        this.questionSection = [];
        this.questionSectionRowMapping = {} as {any: QuestionSectionRow};

        for (let i = 0; i < questions.length; i ++) {
            let qst = new QuestionSectionRow(questions[i]);
            this.questionSectionRowMapping[qst.question.id] = qst;
            this.questionSection.push(qst);
        }
    }

    setFilter(index, filterData): void {
        this.filterSection[index].selfUpdate(filterData);
    }

    applyFilter(index, statsData): void {
        for (let [questionId, questionRow] of Object.entries(this.questionSectionRowMapping)) {
            let stats = {};
            let existingStats = statsData.questions_stats[questionId];
            if (existingStats) {
                stats = statsData.questions_stats[questionId].stats;
            }
            questionRow.setStats(index, stats);
        }

        for (let [phaseSectionIdentifier, phaseRow] of Object.entries(this.phaseSectionRowMapping)) {
            let stats = {};
            let existingStats = statsData.total_stats[phaseSectionIdentifier];
            if (existingStats) {
                stats = statsData.total_stats[phaseSectionIdentifier].stats;
            }
            phaseRow.setStats(index, stats);
        }

        // this.sortBy(index, 'lowest_score');
    }

    removeStats(index): void {
        this.questionSection.forEach(qsct => {
            qsct.removeStats(index);
        });

        this.phaseSection.forEach(phsct => {
            phsct.removeStats(index);
        });
    }

    sortBy(index, what): void {
        this.lastSortedIndex = index;

        let isNullable = [] as QuestionSectionRow[];
        let isNotNullable = [] as QuestionSectionRow[];

        this.questionSection.forEach((qnItem: QuestionSectionRow) => {
            if (qnItem.rowStats[index].isNullable) {
                isNullable.push(qnItem);
            } else {
                isNotNullable.push(qnItem);
            }
        })

        switch (what) {
            case 'highest_score':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow2.rowStats[index].score - qsRow1.rowStats[index].score).concat(isNullable);
                break;
            case 'lowest_score':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow1.rowStats[index].score - qsRow2.rowStats[index].score).concat(isNullable);
                break;
            case 'highest_importance_score':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow2.rowStats[index].importance - qsRow1.rowStats[index].importance).concat(isNullable);
                break;
            case 'lowest_importance_score':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow1.rowStats[index].importance - qsRow2.rowStats[index].importance).concat(isNullable);
                break;
            case 'highest_negative_gap':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow1.rowStats[index].gap - qsRow2.rowStats[index].gap).concat(isNullable);
                break;
            case 'highest_positive_gap':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow2.rowStats[index].gap - qsRow1.rowStats[index].gap).concat(isNullable);
                break;
            case 'highest_standard_deviation':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow1.rowStats[index].score - qsRow2.rowStats[index].score).concat(isNullable);
                break;
            case 'lowest_standard_deviation':
                this.questionSection = isNotNullable.sort((qsRow1: QuestionSectionRow, qsRow2: QuestionSectionRow) => qsRow1.rowStats[index].score - qsRow2.rowStats[index].score).concat(isNullable);
                break;
        }
    }

    isColumnEmpty(index): boolean {
        if (!this.questionSection[0]) {
            return true;
        }
        return !this.questionSection[0].rowStats[index];
    }

    setColumnLoadingState(index: number, isLoading: boolean): void {
        this.setContainerLoadingState(isLoading);
        if (isLoading) {
            this.loadingColumns[index] = 'loading';
        } else {
            delete this.loadingColumns[index];
        }
    }

    isColumnLoading(index): boolean {
        return !!this.loadingColumns[index];
    }

    setContainerLoadingState(isLoading = true): void {
        this.isContainerLoading = isLoading;
    }

    clearSortIndex(): void {
        this.lastSortedIndex = null;
    }

    unsetSortIndex(index): void {
        if (this.lastSortedIndex === index) {
            this.clearSortIndex();
        }
    }
}

class QuestionSectionRow {
    question: Question;
    rowStats = [] as Stats | null [];

    constructor(obj: any) {
        this.question = new Question(obj);
    }

    setStats(index, statsData): void {
        this.rowStats[index] = new Stats(statsData);
    }

    removeStats(index): void {
        this.rowStats[index] = null;
    }
}

class Question {
    id: number;
    text: string;
    phase: string;

    constructor(obj: any) {
        this.id = obj.id;
        this.text = obj.text;
        this.phase = obj.phase;
    }
}

const TOTAL_IDENTIFIER = 'Total';
class PhaseStatsRow {
    name: string;
    isTotal = false as boolean;
    rowStats = [] as Stats | null [];

    constructor(name: string) {
        this.name = name;
        if (name === TOTAL_IDENTIFIER) this.isTotal = true;
    }

    setStats(index, statsData): void {
        this.rowStats[index] = new Stats(statsData);
    }

    removeStats(index): void {
        this.rowStats[index] = null;
    }
}

class Stats {
    score: number;
    importance: number;
    gap: number;
    isNullable: boolean;

    constructor(obj: any) {
        this.score = obj.score ? parseFloat(obj.score) : 0;
        this.importance = obj.importance ? parseFloat(obj.importance) : 0;
        this.gap = obj.gap ? parseFloat(obj.gap) : 0;
        this.isNullable = !this.score;
    }
}

class CompareFilter {
    index: number;
    bank_ids = [] as number[];
    countries = [] as string[];

    constructor(index, obj: any = null) {
        this.index = index;
        this.selfUpdate(obj);
    }

    selfUpdate(filterData): void {
        if (filterData && filterData.countries) this.countries = filterData.countries;
        if (filterData && filterData.bank_ids) this.bank_ids = filterData.bank_ids;
    }

    isEmpty(): boolean {
        return this.bank_ids.length === 0 && this.countries.length === 0;
    }

    isValid(): boolean {
        return this.bank_ids.length > 0 && this.countries.length > 0;
    }

    clear(): void {
        this.countries = [];
        this.bank_ids = [];
    }
}
